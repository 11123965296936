<template>
  <div class="newmember">
    <h1 class="header-title">
      Приветствуем тебя на официальном сайте сообщества «Анонимные Наркоманы»
      город Смоленск
    </h1>
    <div class="newmember-body__text">
      <p class="text-block">
        Ничто не может заменить живое общение и поэтому, чтобы быстрее понять,
        кто мы и как можем тебе помочь — мы приглашаем прямо сегодня посетить
        одну из встреч участников сообщества АН, которые проходят в городе
        Смоленска.
      </p>
    </div>
    <div class="newmember-body__img">
      <i class="fas fa-chevron-right"></i>
      <div class="newmember-body__img-text">
        Мы предлагаем простую и бесплатную программу выздоровления от наркомании
      </div>
      <i class="fas fa-chevron-left"></i>
    </div>
    <div class="newmemeber-body__text">
      <p class="text-block">
        <strong
          >Вполне возможно, что совсем рядом кто-то сейчас говорит о тех же
          проблемах что волнуют тебя.
        </strong>
        <router-link class="router-link" to="/timetable"
          >Расписание групп</router-link
        >
        поможет найти подходящее собрание, и это будет первым шагом на пути к
        выздоровлению от зависимости. Также проходят собрания в онлайн режиме.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    document.title = "Новичку";
  },
};
</script>

<style lang="scss">
$bg-gradient: linear-gradient(
  0deg,
  rgba(2, 0, 36, 1) 0%,
  rgba(9, 9, 121, 1) 34%,
  rgba(0, 212, 255, 1) 85%
);
.newmember-body {
  &__img {
    height: 200px;
    width: 90%;
    margin: auto;
    background: $bg-gradient;
    position: relative;
    font-size: 10rem;
    color: #fff;
    padding: 50px 0;
    i {
      position: absolute;
      top: 10%;
      &:nth-child(1) {
        left: 0;
      }
      &:nth-child(3) {
        right: 0;
      }
    }
    &-text {
      font-size: 27px;
      width: 450px;
      margin: auto;
      text-align: center;
      @media screen and (max-width: 1014px) {
        font-size: 18px;
        width: 250px;
      }
    }
    @media screen and (max-width: 1014px) {
      font-size: 5rem;
      i {
        top: 30%;
      }
    }

    @media (max-width: 850px) {
      width: 100%;
    }

    @media (max-width: 320px) {
      font-size: 3rem;
      i {
        top: 40%;
      }
    }
  }
}
</style>

